.service-about-section{
    margin-top: 3%;
}
.about-content-container {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Add some gap between the content and image */
    margin: 30px 0; /* Add some space between top and bottom */
  }
  
  @media (min-width: 768px) {
    .about-content-container {
      flex-direction: row;
    }
  }
  
  .about-content-container .content-part {
    width: 100%;
    text-align: justify; /* Add text justification */
    padding: 0 10px; /* Add padding for gap */
  }  
  
  @media (min-width: 768px) {
    .about-content-container .content-part {
        width: 60%;
      }
  }
  
  .about-content-container .image-part {
    width: 100%;
  }
  
  @media (min-width: 768px) {
    .about-content-container .image-part {
      width: 40%; /* Set the width of the image to 40% */
    }
  }
  
  /* For why section */
  .why-content-container {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Add some gap between the content and image */
    margin: 20px 0; /* Add some space between top and bottom */
  }
  
  @media (min-width: 768px) {
    .why-content-container {
      flex-direction: row-reverse;
    }
  }
  
  .why-content-container .content-part {
    width: 100%;
    text-align: justify; /* Add text justification */
    padding: 0 10px; /* Add padding for gap */
  }
  
  
  @media (min-width: 768px) {
    .why-content-container .content-part {
      width: 60%; /* Set the width of the content to 60% */
    }
  }
  
  .why-content-container .image-part {
    width: 100%;
  }
  
  @media (min-width: 768px) {
    .why-content-container .image-part {
      width: 40%; /* Set the width of the image to 40% */
    }
  }
  