/* Base Styles */
.box{
    object-fit: cover;
   
    width: auto;
}
.box img{
    width: 100%;
    height: 200px;
}

@media (min-width:320Px){
    .box{
        object-fit: cover;
        
        width: 90%;
        margin-bottom: 20px;
    }
    .box img{
        width: 72%;
    }
    .box h2{
        width: 70%;
    }
    

}
@media (min-width:375Px){
    .box{
        object-fit: cover;
        
        width: 90%;
        margin-bottom: 20px;
    }
    .box img{
        width: 88%;
    }
    .box h2{
        width: 80%;
    }
}
  
@media (min-width:425Px){
    .box{
        object-fit: cover;
        
        width: auto;
        margin-bottom: 20px;
    }
    .box img{
        width: 100%;
    }
    .box h2{
        width: 90%;
    }
}
@media (min-width:768Px){
    .box{
        object-fit: cover;
        
        width: 60%;
        margin-bottom: 20px;
    }
    .box img{
        width: 100%;
    }
    .box h2{
        width: 100%;
    }
}
@media (min-width: 1024px){
    .box{
        object-fit: cover;
        
        width: auto;
        margin-bottom: 20px;
    }
    .box img{
        width: 90%;
    }
    .box h2{
        width: 90%;
    }
}
@media (min-width: 1440px){
    .box{
        object-fit: cover;
        
        width: auto;
        margin-bottom: 20px;
    }
    .box img{
        width: 100%;
    }
    .box h2{
        width: 100%;
    }
}

.shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  
  .border-1px {
    border-width: 1px;
  }
  
  .border-transparent {
    border-color: transparent;
  }
  
  .transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
  
  .duration-100 {
    transition-duration: 100ms;
  }
  
  .delay-100 {
    transition-delay: 100ms;
  }
  
  /* Hover Styles */
  .hover\:border-blue-700:hover {
    border-color: #4299e1;
  }
  
  .hover\:no-underline:hover {
    text-decoration: underline;
    
  }
  
  /* Inner Element Styles */
  .cursor-pointer {
    cursor: pointer;
  }
  
  /* Image Styles */
  .object-cover {
    object-fit: cover;
  }
  
  /* Text Styles */
  .text-base {
    font-size: 1rem;
  }
  
  .font-bold {
    font-weight: 700;
  }
  
  .font-Nunito {
    font-family: 'Nunito', sans-serif;
  }
  
  .font-QuickSand {
    font-family: 'Quicksand', sans-serif;
  }
  
  .text-xs {
    font-size: 0.75rem;
  }
  
  .font-medium {
    font-weight: 500;
  }
  
  /* Container Styles */
  .px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  
  .py-2-5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  
  .py-1-5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }  
  
  .flex {
    display: flex;
  }
  
  .justify-between {
    justify-content: space-between;
  }
  
  .gap-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
    margin-left: calc(1rem * var(--tw-space-x-reverse));
  }
  
  /* Custom Styles */
  .color-blue-gray {
    color: #718096;
  }
  
  /* Link Styles */
  a {
    color: inherit;
    
  }
  h2{
   padding-top: 20px;
  }