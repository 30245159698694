.whatsapp-linear {
  background: linear-gradient(
    90deg,
    rgba(255, 119, 52, 1) 0%,
    rgba(239, 39, 39, 1) 38%,
    rgba(220, 215, 10, 1) 100%
  );
  color: white !important;
  padding: 0px 30px;
  border-radius: 27px;
  display: flex !important;
  place-items: center;
  column-gap: 10px;
  font-size: medium;
  font-weight: 500;
  height: 55px !important;
  transition: none !important;
  text-decoration: none !important;
}

.mainmenu > li > a::before {
  transition: none !important;
  content: "";
  height: 0;
  width: 0;
  background: none !important;
  /* background-color: var(--color-primary); */
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  /* transition: 0.5s; */
}

.dropdownnav {
  position: relative;
}

.dropdownnav-menu {
  display: none;
  position: absolute;
  justify-content: center;
  top: 100%;
  left: 0;
  z-index: 999;
  padding: 10px;
  padding-left: 30px;
  padding-right: 20px;
  background-color: #fff;
  width: 230px; /* Set the width to fit the content */
  opacity: 0; /* Initially set opacity to 0 */
  visibility: hidden; /* Initially set visibility to hidden */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1); /* Add box shadow */
  transition: opacity 0.3s ease, visibility 0.6s ease; /* Add transition for opacity and visibility */
}

.dropdownnav-menu.open {
  display: block;
  opacity: 1; /* Change opacity to 1 when menu is open */
  visibility: visible; /* Change visibility to visible when menu is open */
}

/* Styles for each service */
.dropdownnav-menu .nav-service {
  display: block;
  margin-bottom: 8px; /* Add some vertical spacing between services */
  
}

.dropdownnav-menu .nav-service .nav-service-link {
  color: #161616;
  text-decoration: none;
  font-family: Arial, sans-serif; /* Set a font */
  font-weight: 550;
  font-size: 14px; /* Set font size */
  position: relative; /* Add relative position to create space for underline */
}

.dropdownnav-menu .nav-service .nav-service-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -2px; /* Adjust to position the underline */
  left: 0;
  background-color: transparent;
  transition: background-color 0.3s ease; /* Add transition for underline */
}

.dropdownnav-menu .nav-service .nav-service-link:hover::after {
  background-color: rgb(168, 91, 168); /* Change background color to purple on hover */
}

.dropdownnav-menu .nav-service .nav-service-link:hover {
  color: rgb(168, 91, 168); /* Change text color to purple on hover */
}

.dropdownnav:hover .dropdownnav-menu {
  display: block;
}



