/* Define custom styles */
.latest_card{
  margin-top: 10%;
  position: relative;
}

.latest_card::after {
    content: '';
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    height: 1px; /* Height of the underline */
    background-color: #4e4e4e; /* Color of the underline */
}
.latest_card p {
  background-color: #4e4e4e;
}

.text-gradient {
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image: linear-gradient(to right, #ffed4a, #ff3860, #007bff);
  }
  
  .bg-violet-main {
    background-color: #8a3ffc;
  }
  
  .border-violet-main {
    border-color: #8a3ffc;
  }
  
  /* Apply styles to HTML elements */
  .max-w-screen-xl {
    max-width: 1280px;
  }
  
  .px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  
  .py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  
  .text-6xl {
    font-size: 4rem;
  }
  
  .font-extrabold {
    font-weight: 800;
  }
  
  .uppercase {
    text-transform: uppercase;
  }
  
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .leading-4_5rem {
    line-height: 4.5rem;
  }
  
  .bg-clip-text {
    background-clip: text;
  }
  
  .from-yellow-dark {
    background-color: #ffb100;
  }
  
  .via-red-500 {
    background-color: #ef4444;
  }
  
  .to-blue-darkBlue {
    background-color: #1e3a8a;
  }
  
  .bg-4e4e4e {
    background-color: #4e4e4e;
  }
  
  .font-Inter {
    font-family: 'Inter', sans-serif;
  }
  
  .inline-block {
    display: inline-block;
  }
  
  .text-gray-700 {
    color: #4b5563;
  }
  
  .font-normal {
    font-weight: 400;
  }
  
  .text-base {
    font-size: 1rem;
  }
  
  .text-white {
    color: #fff;
  }
  
  .py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  
  .px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  .border-b-1px {
    border-bottom-width: 1px;
  }
  
  .mb-6 {
    margin-bottom: 1.5rem;
  }
  
  .mt-6 {
    margin-top: 1.5rem;
  }
  


  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 1024px) {
    .blogcard {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1.25rem; /* Adjust as needed */
      padding: 0 1.25rem; /* Adjust as needed */
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    
  }
  @media (min-width: 1440px) {
    .blogcard {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1.25rem; /* Adjust as needed */
      padding: 0 1.25rem; /* Adjust as needed */
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    
  }
  
  /* Large devices (desktops, 992px and up) */
  
  
  .grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  
  .gap-5 {
    gap: 1.25rem;
  }
  
  .px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  
  .lg\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  
  .w-full {
    width: 100%;
  }
  
  .text-center {
    text-align: center;
  }
  
  .pt-28 {
    padding-top: 7rem;
  }

  
  /* You can add more custom styles as needed */
  