/* Base Styles */
.full-page {
    padding-top: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    padding-left: 8px;
    padding-right: 8px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Responsive Styles */
  @media (min-width: 1024px) {
    .full-page {
      flex-direction: row;
    }
  }

  article{
    /* margin-top: 10%; */
  }
  article h1{
    width: 100%;
  }
  /* Styles for Article Section */
  .flex {
    display: flex;
  }
  
  .flex-col {
    flex-direction: column;
  }
  
  .lg\:col-span-3 {
    flex-grow: 3;
  }
  @media (min-width: 768px) {
    .blogcard-bottom {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1.25rem; /* Adjust as needed */
      padding: 0 1.25rem; /* Adjust as needed */
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    
  }
  @media (min-width: 1024px) {
    .blogcard-bottom {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1.25rem; /* Adjust as needed */
      padding: 0 1.25rem; /* Adjust as needed */
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    
  }
  @media (min-width: 1440px) {
    .blogcard-bottom {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1.25rem; /* Adjust as needed */
      padding: 0 1.25rem; /* Adjust as needed */
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    
  }
  
  /* Large devices (desktops, 992px and up) */
  
  
  .grids-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  
  .md\:grids-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  
  .lg\:grids-cols-1 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  
  /* Loader Styles */
  /* .loader {
   
  }
   */
  /* Styles for Main Image */
  /* .para {
    max-width: 6xl;
    justify-content: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }
  
  .text-4xl {
    font-size: 2.25rem;
  }
  
  .md\:text-justify {
    text-align: justify;
  }
  
  .font-bold {
    font-weight: bold;
  }
  
  .pb-4 {
    padding-bottom: 1rem;
  } */
  
  /* Styles for Author, Category, Date */
  /* .py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  
  .text-xs {
    font-size: 0.75rem;
  }
  
  .font-medium {
    font-weight: 500;
  } */
  
  /* Styles for Content Block */
  /* .blockcontent-style {
    
  } */
  
  /* Read More Button Styles */
  /* .mb-6 {
    margin-bottom: 1.5rem;
  }
  
  .w-auto {
    width: auto;
  }
  
  .capitalize {
    text-transform: capitalize;
  } */
  
  /* .bg-gradient-to-r {
   
  }
  
  .from-slate-900 {
    
  }
  
  .to-slate-700 {
    
  }
  
  .px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  
  .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  .rounded-full {
    border-radius: 9999px;
  } */
  
  /* Styles for Sidebar */
  /* .sidebar {
    padding-top: 1rem;
    padding-bottom: 2.5rem;
  }
  
  .lg\:pt-4 {
    padding-top: 1rem;
  }
  
  .lg\:pb-10 {
    padding-bottom: 2.5rem;
  }
  
  .lg-basis-2-5 {
    flex-basis: 40%;
  } */
  
  /* Sticky Sidebar Styles */
  /* .lg\:sticky {
    position: sticky;
    top: 5%;
  } */
  
  /* Related Posts Styles */
  /* .border-l-2 {
    border-left-width: 2px;
  }
  
  .pl-4 {
    padding-left: 1rem;
  }
  
  .bg-f07029 {
    background-color: #f07029;
  }
  
  .rounded-sm {
    border-radius: 0.125rem;
  } */
  
  /* Grid Styles for Related Posts */
  /* .grid {
    display: grid;
  }
  
  .grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  
  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  
  .gap-4 {
    gap: 1rem;
  } */
  
  /* Media Queries for Responsiveness */
  /* @media (min-width: 768px) {
    .para {
      padding-top: 10px;
    }
  }
  
  @media (min-width: 1024px) {
    .para {
      padding-top: 10px;
    }
  }
  
  @media (min-width: 1024px) {
    .sidebar {
      padding-top: 10px;
      padding-bottom: 20px;
    }
  } */
  
  